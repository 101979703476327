import React from 'react';
import { Box, Heading, Text, Link } from '@chakra-ui/react';
import useInvitation from '@hooks/useInvitation';

import { ENABLE_VIDEO_PREWEDDING } from '@constants/feature-flags';
import loveStoryData from '@/api/love-story';

import { ID_LOVESTORY_SECTION } from '@constants/identifier';
import useLang from '@hooks/useLang';
import { ENABLE_LOVE_STORY } from '@constants/feature-flags';
import { THE_BRIDE, YOUTUBE_PREWEDDING_LINK } from '@constants/index';
import LineDecoration from '@components/Common/LineDecoration';
import { IMG_LOVE_STORY } from '@constants/assets';
import LoveStoryCard from './LoveStoryCard';

import txtWording from './locales';

function LoveStory() {
  const isInvitation = useInvitation();
  const lang = useLang();
  const finalLoveStory = isInvitation
    ? [
        ...loveStoryData,
        {
          img: '',
          title: `25 JUNE 2022`,
          description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam sollicitudin malesuada sapien, sit amet sodales ex sagittis quis. Suspendisse facilisis mi volutpat urna pulvinar, quis aliquet dui lobortis. Sed egestas consequat risus, eu mollis est tincidunt accumsan.
          `,
        },
      ]
    : [
        ...loveStoryData,
        {
          img: '',
          title: `25 JUNE 2022`,
          description: `THE BIG DAY! <br />
    And now, we'd like to share and celebrate the next chapter of our story with you!`,
        },
      ];

  // const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  return (
    <Box bgColor="bgPrimary" padding="3rem 0rem 3rem 0rem" name={ID_LOVESTORY_SECTION}>
      <Heading fontWeight={'medium'} fontSize="28px" textAlign="center">
        <>
          {THE_BRIDE} are <br />
          Getting Married!
        </>
      </Heading>

      <Box width={'100%'} overflow={'hidden'}>
        <Box
          position="relative"
          sx={{
            width: '240px',
            height: '330px',

            '@media screen and (max-width: 400px)': {
              width: '220px',
              height: '300px',
            },
          }}
          bgImage={`url(${IMG_LOVE_STORY})`}
          bgSize="cover"
          bgPosition="center"
          margin={'30px auto'}
        >
          <Box
            position="absolute"
            sx={{
              top: '-33px',
              left: '-190px',
              '@media screen and (max-width: 400px)': {
                top: '-63px',
                left: '-180px',
              },
            }}
          >
            <LineDecoration width="280px" height="380px" />
          </Box>
        </Box>
      </Box>

      {ENABLE_VIDEO_PREWEDDING && (
        <Link href={YOUTUBE_PREWEDDING_LINK} target="_blank">
          <Text
            fontFamily="headingAlternative"
            letterSpacing="2px"
            textTransform="uppercase"
            fontSize={'md'}
            textDecoration="underline"
            textAlign={'center'}
            marginBottom={'30px'}
          >
            {txtWording.video[lang]}
          </Text>
        </Link>
      )}

      {ENABLE_LOVE_STORY && false && <>{<LoveStoryCard loveStoryData={finalLoveStory} />}</>}
    </Box>
  );
}

export default LoveStory;
