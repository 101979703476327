import React from 'react';
import { Box, Center, Text, Link, Heading, Divider, Grid, GridItem } from '@chakra-ui/react';
import Image from '@/components/Common/LazyImage';

import { THE_BRIDE, SOUND_BY, SOUND_URL, URL_INVITATO, BRIDE_HASHTAG } from '@/constants';
import { IMG_FOOTER_1, IMG_FOOTER_2, IMG_FOOTER_3, LOGO_INVITATO } from '@constants/assets';

import { ENABLE_COLLABORATION, SELECTED_VENDOR_COLLABORATION } from '@constants/feature-flags';
import { HASHTAG_STYLE, HEADING_ALTERNATIVE_STYLES, HEADING_STYLES } from '@constants/colors';

import useLang from '@hooks/useLang';
import txtWording from './locales';

function FooterSection() {
  const lang = useLang();

  return (
    <Box bgColor="bgPrimary">
      <Box padding="0 18px 4rem 18px" textAlign="center" bgColor="bgPrimary">
        <Box>
          <Heading {...HEADING_ALTERNATIVE_STYLES} textColor="mainColorText">
            {txtWording.ourSincere[lang]}
          </Heading>
          <Heading
            {...HEADING_STYLES}
            fontSize="32px"
            textTransform="uppercase"
            marginTop={'2rem'}
            marginBottom={'0.8rem'}
          >
            {THE_BRIDE}
          </Heading>
          <Heading {...HASHTAG_STYLE} fontSize="32px">
            {BRIDE_HASHTAG}
          </Heading>
        </Box>
      </Box>
      <Grid
        templateColumns="repeat(3, 1fr)"
        sx={{
          height: '160px',

          '@media screen and (max-width: 450px)': {
            height: '145px',
          },

          '@media screen and (max-width: 400px)': {
            height: '125px',
          },
        }}
        width={'100%'}
      >
        <GridItem
          bgImage={`url(${IMG_FOOTER_1})`}
          bgPosition={'center'}
          bgSize={'cover'}
          colSpan={1}
        ></GridItem>
        <GridItem
          bgImage={`url(${IMG_FOOTER_2})`}
          bgPosition={'center'}
          bgSize={'cover'}
          colSpan={1}
        ></GridItem>
        <GridItem
          bgImage={`url(${IMG_FOOTER_3})`}
          bgPosition={'center'}
          bgSize={'cover'}
          colSpan={1}
        ></GridItem>
      </Grid>
      <Box
        padding="24px 0"
        bgColor="bgSecondary"
        width="100%"
        color="secondaryColorText"
        textAlign="center"
      >
        <Center>
          <Link href={URL_INVITATO} target="_blank">
            <Image src={LOGO_INVITATO} maxWidth="95px" margin="0 0 8px 0" />
          </Link>
        </Center>

        <Link href={URL_INVITATO} target="_blank">
          <Text textAlign="center" fontSize="sm">
            Created with Love by Invitato
          </Text>
        </Link>
        {ENABLE_COLLABORATION && (
          <Box margin="-4px 0 8px" color="mainColorText">
            <Center>
              <Divider
                margin="16px 0"
                maxWidth="30%"
                height="1px"
                bgColor="mainColorText"
                color="mainColorText"
                border="none"
                boxShadow="none"
                borderBottomWidth="none"
              />
            </Center>
            <Center>
              <Link
                href={`https://www.instagram.com/${SELECTED_VENDOR_COLLABORATION.instagram}/`}
                target="_blank"
              >
                <Image
                  src={SELECTED_VENDOR_COLLABORATION.imgUrl}
                  {...SELECTED_VENDOR_COLLABORATION.imgProps}
                />
              </Link>
            </Center>
            <Center>
              <Text align="center" fontSize="xs" marginBottom={'4px'}>
                In Collaboration with {SELECTED_VENDOR_COLLABORATION.vendorName}
              </Text>
            </Center>
          </Box>
        )}
        <Text fontSize="sm">
          {`© ${new Date().getFullYear()} ${THE_BRIDE}. All Rights Reserved`}
        </Text>
        {/* <Link
          textAlign="center"
          href={`https://annaelizabethphoto.com/couples/ojai-california-engagement-session/`}
          fontSize="sm"
          isExternal
        >
          {`Devany and Austin Photo  by Anna Elizabeth`}
        </Link> */}
        {/* <br></br> */}
        <Link textAlign="center" href={SOUND_URL} fontSize="sm" isExternal>
          {`Song by ${SOUND_BY}`}
        </Link>
      </Box>
    </Box>
  );
}

export default FooterSection;
