import React from 'react';
import { Heading, Box, Center, Text } from '@chakra-ui/react';

import useInvitation from '@hooks/useInvitation';

import WithAnimation from '@components/Common/WithAnimation';
// import QRCard from '@components/Common/QRCard/V2';
import WeddingDetail from './WeddingDetail';

import {
  WEDDING_AKAD_FULLDATE,
  WEDDING_AKAD_LOC_NAME,
  WEDDING_RESEPSI_FULLDATE,
  GOOGLE_MAPS_LINK,
  WEDDING_AKAD_TIME,
  WEDDING_AKAD_LOC_ROAD,
  WEDDING_RESEPSI_LOC_NAME,
  WEDDING_RESEPSI_LOC_ROAD,
  WEDDING_RESEPSI_NOTE,
} from '@/constants';
import { HEADING_ALTERNATIVE_STYLES, TEXT_DECORATION } from '@constants/colors';
// import * as ID from '@/constants/identifier';

import txtWording from './locales';
import useLang from '@hooks/useLang';
// import { ENABLE_QR_INVITATION, MUSLIM_INVITATION } from '@constants/feature-flags';
import { MUSLIM_INVITATION } from '@constants/feature-flags';
import { useGuest } from '@/context/guest';
import generateShift from '@hooks/useShift';

import { RiDoubleQuotesL } from 'react-icons/ri';
// import { RSVP_STATUS } from '@constants/api';

function WeddingSection({ ...rest }) {
  const isInvitation = useInvitation();
  const lang = useLang();
  const { guest } = useGuest();

  const shiftTime = generateShift(guest.shift);
  return (
    <>
      <Box bgColor="bgPrimary" {...rest}>
        <Box
          bgColor="bgSecondary"
          textAlign={'center'}
          color="secondaryColorText"
          padding={'3rem 2rem 4rem 2rem'}
        >
          <Center marginBottom={'1rem'}>
            <RiDoubleQuotesL color={TEXT_DECORATION} fontSize={'2.8rem'} />
          </Center>

          <WithAnimation>
            <Text fontSize="18px" fontStyle={'italic'}>
              “{MUSLIM_INVITATION ? txtWording.ayatMuslim[lang] : txtWording.ayat[lang]}”
            </Text>
          </WithAnimation>
          <WithAnimation>
            <Text {...HEADING_ALTERNATIVE_STYLES} marginTop="1.5rem" color="secondaryColorText">
              - {MUSLIM_INVITATION ? txtWording.ayatNameMuslim[lang] : txtWording.ayatName[lang]}
            </Text>
          </WithAnimation>
        </Box>

        <Box padding={'3rem 2rem'}>
          <WithAnimation>
            <Heading
              {...HEADING_ALTERNATIVE_STYLES}
              marginBottom="30px"
              paddingLeft="0px"
              textAlign="left"
            >
              {txtWording.title[lang]}
            </Heading>
          </WithAnimation>

          {/* Wedding info section */}
          <Box marginTop="-16px">
            {isInvitation && (
              <WithAnimation>
                <WeddingDetail
                  date={`${txtWording.date[lang]}: ${WEDDING_AKAD_FULLDATE}`}
                  time={`${txtWording.time[lang]}: ${WEDDING_AKAD_TIME}`}
                  title={
                    MUSLIM_INVITATION ? txtWording.akadMuslim[lang] : txtWording.akadGeneral[lang]
                  }
                  subTitlePlace={
                    isInvitation ? `${txtWording.place[lang]}: ${WEDDING_AKAD_LOC_NAME}` : ''
                  }
                  subTitlePlaceDetail={isInvitation ? WEDDING_AKAD_LOC_ROAD : ''}
                  //location={GOOGLE_MAPS_LINK}
                />
              </WithAnimation>
            )}

            <WithAnimation>
              <Center>
                {isInvitation && (
                  <Box width="1.5px" height="3rem" bgColor={'#7a7771'} margin={'25px 0'}></Box>
                )}
              </Center>

              <WeddingDetail
                date={`${txtWording.date[lang]}: ${WEDDING_RESEPSI_FULLDATE}`}
                time={`${txtWording.time[lang]}: ${shiftTime}`}
                title={txtWording.reception[lang]}
                subTitlePlace={`${txtWording.place[lang]}: ${WEDDING_RESEPSI_LOC_NAME}`}
                subTitlePlaceDetail={WEDDING_RESEPSI_LOC_ROAD}
                location={GOOGLE_MAPS_LINK}
                calendar={isInvitation}
                note={isInvitation && WEDDING_RESEPSI_NOTE}
              />
            </WithAnimation>

            {/* {isInvitation && ENABLE_QR_INVITATION && guest.status !== RSVP_STATUS.notAttend && (
              <WithAnimation>
                <Center>
                  <QRCard isDirectLink={true} name={ID.ID_QRCODE} />
                </Center>
              </WithAnimation>
            )} */}
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default React.memo(WeddingSection);
