import React from 'react';
import { string, object, bool } from 'prop-types';
import { Box, Heading, Text, Button } from '@chakra-ui/react';
import {
  HEADING_STYLES,
  BG_SECONDARY,
  TEXT_PRIMARY,
  TEXT_DECORATION,
  DEFAULT_BUTTON_PROPS,
  ALTERNATE_BUTTON_COLOR,
} from '@constants/colors';
import { GOOGLE_MAPS_LINK } from '@constants/index';

import txtWording from './locales';
import useLang from '@hooks/useLang';
import useInvitation from '@hooks/useInvitation';
import AddToCalendarButton from '@components/Common/AddToCalendar';

function WeddingDetail({
  title,
  titleProps,
  subTitlePlace,
  subTitlePlaceDetail,
  date,
  time,
  dresscode,
  location,
  calendar,
  note,
}) {
  const lang = useLang();
  const handleClickGoogleMaps = () => {
    window.open(GOOGLE_MAPS_LINK, '_blank');
  };
  const isInvitation = useInvitation();

  return (
    <Box color="mainColorTextLighter">
      <Heading
        {...HEADING_STYLES}
        paddingLeft="0px"
        textAlign="left"
        margin="12px 0"
        {...titleProps}
        fontSize={'28px'}
      >
        {title}
      </Heading>

      <Text fontSize="18px" mb={'2px'}>
        {date}
      </Text>
      <Text fontSize="18px" mb={'2px'}>
        {time}
      </Text>
      <Text fontSize="18px" mb={'2px'}>
        {subTitlePlace}
      </Text>
      <Text fontSize="18px" mb={'2px'}>
        {subTitlePlaceDetail}
      </Text>
      {dresscode && <Text fontSize="18px">{`Dresscode: ${dresscode}`}</Text>}
      {note && <Text fontSize="14px" fontStyle="italic">{`*) ${note}`}</Text>}
      {location && isInvitation && (
        <Button
          size="sm"
          onClick={handleClickGoogleMaps}
          backgroundColor={BG_SECONDARY}
          textColor="mainColorTextLight"
          borderRadius={'0px'}
          fontSize="lg"
          fontWeight={'light'}
          position="center"
          style={{ fontStyle: 'italic' }}
          marginTop="16px"
          marginRight={{base: "8px", sm: "0px"}}
          borderWidth="1px"
          borderStyle="solid"
          borderColor={TEXT_PRIMARY}
          _hover={{
            backgroundColor: TEXT_DECORATION,
            borderColor: TEXT_PRIMARY,
            transform: 'none',
          }}
        >
          {txtWording.location[lang]}
        </Button>
      )}
      {calendar && (
        <AddToCalendarButton
          text={txtWording.calendar[lang]}
          {...DEFAULT_BUTTON_PROPS}
          fontSize="lg"
          padding="0 12px"
          margin={{base: "16px 0 0 0px", sm:"16px 0 0 16px"}}
          bgColor={ALTERNATE_BUTTON_COLOR}
          color="white"
        />
      )}
    </Box>
  );
}

WeddingDetail.propTypes = {
  date: string.isRequired,
  dresscode: string,
  note: string,
  subTitlePlace: string,
  subTitlePlaceDetail: string,
  time: string.isRequired,
  title: string.isRequired,
  titleProps: object,
  location: string,
  calendar: bool,
};

WeddingDetail.defaultProps = {
  dresscode: '',
  note: '',
  location: '',
  titleProps: {},
  calendar: false,
};

export default React.memo(WeddingDetail);
