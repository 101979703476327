import React, { useState } from 'react';
import { arrayOf, bool, object, number } from 'prop-types';
import { Box, Text } from '@chakra-ui/react';
import Carousel from 'react-simply-carousel';
import { wrapper } from './styles';
// import './styles.css';

const WishItem = (props) => {
  const { item, index } = props;
  return (
    <Box key={`wishlist-${index}`} w="300px" textAlign="center" padding="16px 0 8px 0">
      <Text fontSize="lg" color="secondaryColorText" fontStyle={'italic'} paddingBottom={'10px'}>
        {item.wish}
      </Text>
      <Text
        margin="0 0 16px 0"
        fontWeight="bold"
        fontSize="sm"
        color="secondaryColorText"
        fontFamily="headingAlternative"
        textTransform="uppercase"
      >
        - {item.name.replace('&', '+')}
        {item.relationship !== '' && `, ${item.relationship}`}
      </Text>
    </Box>
  );
};

WishItem.propTypes = {
  item: object,
  index: number,
};

WishItem.defaultProps = {
  item: {},
  index: 0,
};

function Wishlist({ wishlistData }) {
  const [activeSlide, setActiveSlide] = useState(0);

  const renderWishlist = () => {
    let finalResult = [];
    let wishlist = [];
    let pinnedWishlist = [];

    wishlistData.forEach((item, index) => {
      if (item.isPinByAdmin === 1) {
        pinnedWishlist.push(<WishItem key={index} item={item} index={index} />);
      } else {
        wishlist.push(<WishItem key={index} item={item} index={index} />);
      }
    });

    wishlist = wishlist.reverse();
    finalResult = [...pinnedWishlist, ...wishlist];

    return finalResult;
  };

  return (
    <Box position={'relative'} marginTop={'0.5rem'} css={wrapper}>
      {wishlistData.length > 0 && (
        <Carousel
          autoplay
          autoplayDelay={3000}
          preventScrollOnSwipe
          swipeTreshold={60}
          itemsToShow={1}
          centerMode
          speed={400}
          activeSlideIndex={activeSlide}
          onRequestChange={setActiveSlide}
          responsiveProps={[{ itemsToShow: 1, centerMode: true, minWidth: 200 }]}
          dotsNav={{
            show: true,
            containerProps: {
              className: 'dots-container',
              style: {
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              },
            },
            itemBtnProps: {
              style: {
                height: 4,
                width: 4,
                borderRadius: '50%',
                border: 0,
                margin: 2,
                background: 'var(--chakra-colors-whiteAlpha-500)',
              },
            },
            activeItemBtnProps: {
              style: {
                height: 4,
                width: 4,
                borderRadius: '50%',
                border: 0,
                margin: 2,
                background: 'var(--chakra-colors-whiteAlpha-900)',
              },
            },
          }}
        >
          {renderWishlist()}
        </Carousel>
      )}
    </Box>
  );
}

Wishlist.propTypes = {
  loading: bool,
  wishlistData: arrayOf(object),
};

Wishlist.defaultProps = {
  loading: false,
  wishlistData: [],
};

export default Wishlist;
