import get from '@invitato/helpers/dist/getLocale';

export default {
  title: get('WAKTU & TEMPAT', 'PLACE & TIME'),
  ayatName: get('Q.S. Ar-Rum : 21', 'Q.S. Ar-Rum: 21'),
  ayatNameMuslim: get('Q.S. Ar-Rum : 21', 'Q.S. Ar-Rum: 21'),
  ayat: get(
    `Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan pasangan-pasangan untukmu dari jenismu sendiri, agar kamu cenderung dan merasa tenteram kepadanya, dan Dia menjadikan diantaramu rasa kasih dan sayang. Sungguh, pada yang demikian itu benar-benar terdapat tanda-tanda (kebesaran Allah) bagi kaum yang berpikir.`,
    `And of His signs is that He created for you from yourselves mates that you may find tranquility in them, and He placed between you affection and mercy. Indeed in that are signs for a people who give thought.`,
  ),
  ayatMuslim: get(
    `Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan pasangan-pasangan untukmu dari jenismu sendiri, agar kamu cenderung dan merasa tenteram kepadanya, dan Dia menjadikan diantaramu rasa kasih dan sayang. Sungguh, pada yang demikian itu benar-benar terdapat tanda-tanda (kebesaran Allah) bagi kaum yang berpikir.`,
    `And of His signs is that He created for you from yourselves mates that you may find tranquility in them, and He placed between you affection and mercy. Indeed in that are signs for a people who give thought.`,
  ),
  location: get('Lihat Lokasi', 'See Location'),
  akadMuslim: get('Akad Nikah', 'The Religious Ceremony'),
  akadGeneral: get('Pemberkatan', 'Holy Matrimony'),
  reception: get('Resepsi', 'Wedding Reception'),
  calendar: get('Ingatkan Acara', 'Add to Calendar'),
  date: get('Tanggal', 'Date'),
  time: get('Waktu', 'Time'),
  place: get('Tempat', 'Place'),
};
