import { TEXT_SECONDARY, ALTERNATE_BUTTON_COLOR } from '@constants/colors';

export const TYPE = {
  YES: 'YA',
  NO: 'TIDAK',
};

export const ERROR_TYPE = {
  name: undefined,
  email: undefined,
  phone: undefined,
  address: undefined,
};

export const INPUT_COMMON_PROPS = {
  isRequired: true,
  size: 'md',
  type: 'text',
  variant: 'outline',
  colorScheme: 'blackAlpha',
  backgroundColor: 'bgPrimary',
  color: 'mainColorText',
  fontSize: 'md',
  borderRadius: '0',
  fontFamily: 'body',
  _placeholder: { color: 'mainColorText' },
  _focus: { borderColor: 'mainColorText' },
  borderColor: '#7a7771',
};

export const SELECT_COMMON_PROPS = {
  borderRadius: '0',
  size: 'md',
  variant: 'outline',
  backgroundColor: 'bgPrimary',
  borderColor: '#7a7771',
  color: 'mainColorText',
  fontFamily: 'body',
  fontSize: 'md',
  fontWeight: '300',
  _focus: { borderColor: 'mainColorText' },
};

export const RSVP_BUTTON_PROPS = {
  width: '140px',
  color: 'mainColorText',
  fontWeight: 'light',
  size: 'sm',
  fontSize: '18px',
  padding: '17px 0',
  fontStyle: 'italic',
  borderRadius: '0',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: '#1A1B1D',
  bgColor: 'bgAlternative',
  marginTop: '8px',
};

export const RSVP_STATE = {
  attend: 'attend',
  info: 'info',
  confirmation: 'confirmation',
  submitted: 'submitted',
};

export const BTN_ALTERNATE = {
  backgroundColor: ALTERNATE_BUTTON_COLOR,
  color: TEXT_SECONDARY,
};

export const getStepNumber = (state) => {
  switch (state) {
    case RSVP_STATE.attend:
      return 0;
    case RSVP_STATE.info:
      return 1;
    case RSVP_STATE.confirmation:
      return 2;
    case RSVP_STATE.submitted:
      return 2;
    default:
      return 1;
  }
};
