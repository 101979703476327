import React from 'react';
import { bool, func, object, string } from 'prop-types';
import { Button, Center } from '@chakra-ui/react';
import { BG_SECONDARY, DEFAULT_BUTTON_PROPS, TEXT_PRIMARY } from '@constants/colors';

/**
 * function to render button scroll down
 * @returns {JSX.Element}
 */
function ScrollToDown({ loading, onClick, text, style, buttonProps }) {
  return (
    <Center style={style}>
      <Button
        colorScheme="blackAlpha"
        isLoading={loading}
        onClick={onClick}
        className="animation-up-and-down"
        color="mainColorText"
        _hover={{ backgroundColor: BG_SECONDARY, borderColor: TEXT_PRIMARY, transform: 'none' }}
        {...DEFAULT_BUTTON_PROPS}
        padding="0 2.4rem"
        fontSize="lg"
        fontWeight="normal"
        {...buttonProps}
      >
        {text}
      </Button>
    </Center>
  );
}

ScrollToDown.propTypes = {
  loading: bool.isRequired,
  text: string,
  style: object,
  buttonProps: object,
  withIcon: bool,
  onClick: func.isRequired,
};

ScrollToDown.defaultProps = {
  buttonProps: {},
  text: 'Open',
  withIcon: true,
  style: {},
};

export default React.memo(ScrollToDown);
