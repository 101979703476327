import React from 'react';
import { number, string } from 'prop-types';
import { Box, Text } from '@chakra-ui/react';

function CountItem({ number, text }) {
  return (
    <Box
      margin="4px"
      padding="8px 4px"
      width="60px"
      height="65px"
      bgColor="bgSecondary"
      borderWidth="1px"
      borderStyle="solid"
      borderColor="mainColorText"
    >
      <Box>
        <Text textAlign="center" fontSize="2xl" color="secondaryColorText" fontFamily="heading">
          {number}
        </Text>
      </Box>
      <Box>
        <Text
          textAlign="center"
          color="secondaryColorText"
          fontFamily="serif"
          fontStyle={'italic'}
          fontSize="xs"
          marginTop="-4px"
        >
          {text}
        </Text>
      </Box>
    </Box>
  );
}

CountItem.propTypes = {
  number: number.isRequired,
  text: string.isRequired,
};

export default React.memo(CountItem);
