import React, { useState, useEffect } from 'react';
import { func, bool } from 'prop-types';

import { Box, Center, Heading } from '@chakra-ui/react';

import playMusic from '@/components/Common/FloatingMusic/utils/playMusic';
import ScrollToDown from '@/components/Common/ScrollToDown/V1';

import { HASHTAG_STYLE, HEADING_ALTERNATIVE_STYLES, HEADING_STYLES } from '@/constants/colors';
import { BG_COVER } from '@/constants/assets';
import { BOY_NAME_SHORT, GIRL_NAME_SHORT, IS_BOY_FIRST, BRIDE_HASHTAG } from '@constants/index';
import useInvitation from '@hooks/useInvitation';
import useLang from '@hooks/useLang';
import { useGuest } from '@/context/guest';

import txtWording from './locales';

/**
 * Function to render Component CoverV3
 * This component will be dissapear after user click show `Undangan`
 * @return {JSX.Element}
 */
function Cover({ loaded, onSeeDetail }) {
  const { isLoading } = useGuest();
  const [loading, setLoading] = useState(false);

  const isInvitation = useInvitation();
  const lang = useLang();

  const handleLoadingComplete = () => {
    onSeeDetail();
    setLoading(false);
  };

  const handleClickDetail = () => {
    if (loading) return;

    playMusic();
    setLoading(true);
  };

  useEffect(() => {
    // side-effect to show next page after guest data ready
    // @important we use interval to make sure guest data is ready first before we go to next section
    if (loading) {
      let intervalGuest = setInterval(() => {
        if (!isLoading) {
          clearInterval(intervalGuest);
          handleLoadingComplete();
        }
      }, 1500);
    }
  }, [loading, isLoading, isInvitation]);

  return (
    <Box minHeight="100vh" color="secondaryColorText">
      <Box
        height="100vh"
        width="100%"
        bgSize="cover"
        bgPosition="center"
        bgImage={`linear-gradient(rgb(50 48 48/ 50%), rgb(50 48 48 / 50%)),url(${BG_COVER})`}
        padding="2rem"
        marginBottom="-170px"
      >
        <Heading marginTop="60px" marginBottom="8px" {...HEADING_ALTERNATIVE_STYLES}>
          {isInvitation ? 'WEDDING INVITATION' : 'WEDDING ANNOUNCEMENT'}
        </Heading>
        <Center height="calc(90% - 130px)">
          <Box textAlign="center">
            <Heading textTransform="uppercase" {...HEADING_STYLES}>
              {IS_BOY_FIRST ? BOY_NAME_SHORT : GIRL_NAME_SHORT} &{' '}
              {IS_BOY_FIRST ? GIRL_NAME_SHORT : BOY_NAME_SHORT}
            </Heading>
            <Heading {...HASHTAG_STYLE}>{BRIDE_HASHTAG}</Heading>
          </Box>
        </Center>
      </Box>
      <Box transform="translateY(-12vh)">
        <Center>
          <ScrollToDown
            loaded={loaded}
            loading={loading}
            marginTop="-16px"
            onClick={handleClickDetail}
            text={txtWording.button[lang]}
          />
        </Center>
      </Box>
    </Box>
  );
}

Cover.propTypes = {
  loaded: bool.isRequired,
  onSeeDetail: func.isRequired,
};

export default Cover;
