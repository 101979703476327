import React, { useState, useEffect } from 'react';
import { bool, string } from 'prop-types';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  CloseButton,
  FormControl,
  FormErrorMessage,
  Heading,
  Input,
  Textarea,
  Text,
  useToast,
} from '@chakra-ui/react';

import WithAnimation from '@components/Common/WithAnimation';
import Wishlist from './WishlishCard';

import txtWording from './locales';
import { ALERT, ERROR_TYPE, INPUT_COMMON_PROPS } from './types';
import { DEFAULT_BUTTON_PROPS, HEADING_STYLES, ALTERNATE_BUTTON_COLOR } from '@constants/colors';
import { ID_WISH_SECTION } from '@constants/identifier';

import { useGuest } from '@/context/guest';
import useWishes from '@/usecase/use-wishes';
import useFormFields from '@hooks/useFormFields';
import useInvitation from '@hooks/useInvitation';

function WishesSection({ lang }) {
  const [showAlert, setShowAlert] = useState(ALERT);
  const [errorType, setErrorType] = useState(ERROR_TYPE);

  const { guest, isRegistered } = useGuest();
  const { code, name } = guest;
  const { isLoading, isMutating, data, error, onPostWish, onRefetch } = useWishes();
  const isInvitation = useInvitation();

  const { formFields, createChangeHandler, onResetForm } = useFormFields({
    name: isRegistered ? name : isInvitation ? '' : name,
    relationship: '',
    wish: '',
  });

  useEffect(() => {
    onResetForm();
  }, [isRegistered]);

  const toast = useToast();

  const handleSetAlert = (isSuccess) => {
    let messageTitle = txtWording.success[lang];
    let messageContent = txtWording.successMessage[lang];

    if (!isSuccess) {
      toast({ status: 'error', title: 'Oops!', description: txtWording.failedMessage[lang] });
    } else {
      toast({
        status: 'success',
        title: messageTitle,
        description: messageContent,
        isClosable: true,
      });
    }
  };

  /**
   * function to submit wishlist data
   * @param {FormEvent}
   * @returns {void}
   */
  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorType(ERROR_TYPE);

    if (!formFields.name || !formFields.relationship || !formFields.wish) {
      setErrorType({
        name: !formFields.name && txtWording.requiredField[lang],
        relationship: !formFields.relationship && txtWording.requiredField[lang],
        wish: !formFields.wish && txtWording.requiredField[lang],
      });
      return;
    }

    await onPostWish({
      code,
      name: formFields.name,
      relationship: formFields.relationship,
      wish: formFields.wish,
      onError: () => {
        handleSetAlert(false);
      },
      onSuccess: () => {
        onResetForm();
        handleSetAlert(true);
        onRefetch();
      },
    });
  };

  const renderAlert = () => {
    if (!showAlert.messageTitle) return null;

    return (
      <Box paddingBottom="0" paddingTop="16px">
        <Alert status={showAlert.success ? 'success' : 'error'} borderRadius="lg">
          <AlertIcon />
          <AlertDescription fontSize="sm">{showAlert.messageContent}</AlertDescription>
          <CloseButton
            position="absolute"
            right="8px"
            top="8px"
            size="sm"
            onClick={() => setShowAlert(ALERT)}
          />
        </Alert>
      </Box>
    );
  };

  return (
    <Box padding="3rem 2rem" bgColor="bgSecondary" name={ID_WISH_SECTION}>
      {/* Title & Description sections */}
      <Box textAlign="center" color="secondaryColorText">
        <WithAnimation>
          <Heading {...HEADING_STYLES} marginBottom={'1rem'} fontSize={'32px'}>
            {txtWording.title[lang]}
          </Heading>
        </WithAnimation>
        <Text fontSize="18px">{txtWording.desc[lang]}</Text>
      </Box>
      {renderAlert()}
      {/* Box for FORM */}
      <WithAnimation>
        <Box paddingTop="2">
          <FormControl margin="12px auto 18px auto" width={'280px'} isInvalid={errorType.name}>
            <Input
              {...INPUT_COMMON_PROPS}
              placeholder={txtWording.name[lang]}
              onChange={createChangeHandler('name')}
              value={formFields.name}
            />
            <FormErrorMessage marginTop="4px">{errorType.name}</FormErrorMessage>
          </FormControl>
          <FormControl
            margin="12px auto 18px auto"
            width={'280px'}
            isInvalid={errorType.relationship}
          >
            <Input
              {...INPUT_COMMON_PROPS}
              placeholder={txtWording.relationship[lang]}
              onChange={createChangeHandler('relationship')}
              value={formFields.relationship}
            />
            <FormErrorMessage marginTop="4px">{errorType.relationship}</FormErrorMessage>
          </FormControl>
          <FormControl margin="12px auto 18px auto" width={'280px'} isInvalid={errorType.wish}>
            <Textarea
              {...INPUT_COMMON_PROPS}
              placeholder={txtWording.wish[lang]}
              onChange={createChangeHandler('wish')}
              value={formFields.wish}
            />
            <FormErrorMessage marginTop="4px">{errorType.wish}</FormErrorMessage>
          </FormControl>
          <Box marginX={'auto'} width={'280px'} display={'flex'} justifyContent={'end'}>
            <Button
              isLoading={isMutating}
              size="md"
              marginBottom="34px"
              onClick={handleSubmit}
              {...DEFAULT_BUTTON_PROPS}
              bgColor={ALTERNATE_BUTTON_COLOR}
              color="white"
              display="flex"
              justifyContent="center"
              alignItems="center"
              fontSize={'18px'}
              padding="16px 30px"
            >
              {txtWording.send[lang]}
            </Button>
          </Box>
        </Box>
      </WithAnimation>
      {/* Wishlist Card */}
      {!error && (
        <WithAnimation>
          <Wishlist wishlistData={data} loading={isLoading} />
        </WithAnimation>
      )}
    </Box>
  );
}

WishesSection.propTypes = {
  lang: string,
  inverse: bool,
};

WishesSection.defaultProps = {
  lang: 'en',
  inverse: false,
};

export default React.memo(WishesSection);
