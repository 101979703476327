import React, { useEffect } from 'react';
import { Box, Text } from '@chakra-ui/react';

import WithAnimation from '@components/Common/WithAnimation';
import LineDecoration from '@components/Common/LineDecoration';
import DetailCard from './DetailCard';

import useRsvp from '@/usecase/use-rsvp';
import useCobaLink from '@/hooks/useCobaLink';

import {
  GIRL_NAME,
  BOY_NAME,
  IG_GIRL,
  IG_BOY,
  GIRL_PARENT_NAME,
  BOY_PARENT_NAME,
  IS_BOY_FIRST,
} from '@/constants';
import { IMG_MAN, IMG_GIRL, IMG_BRIDE_GROOM } from '@/constants/assets';
import { ID_COUPLE_SECTION } from '@constants/identifier';
import { HEADING_ALTERNATIVE_STYLES } from '@constants/colors';

function CoupleInfo() {
  const { onOpenInvitation } = useRsvp();
  const { onValidateCobaLink } = useCobaLink();

  useEffect(() => {
    onValidateCobaLink(onOpenInvitation);
  }, []);

  return (
    <Box
      name={ID_COUPLE_SECTION}
      bgColor="bgPrimary"
      padding={{base: '1rem .7rem 2rem .7rem', md: '1rem 2rem 2rem 2rem'}}
      textAlign={'center'}
      overflow={'hidden'}
    >
      <WithAnimation>
        <Box {...HEADING_ALTERNATIVE_STYLES}>
          <Text>MEET THE {IS_BOY_FIRST ? 'GROOM & BRIDE' : 'BRIDE & GROOM'}</Text>
        </Box>
      </WithAnimation>

      <Box
        position="relative"
        sx={{
          width: '280px',
          height: '280px',

          '@media screen and (max-width: 400px)': {
            width: '255px',
            height: '255px',
          },
        }}
        bgImage={`url(${IMG_BRIDE_GROOM})`}
        bgSize="cover"
        bgPosition="center"
        margin={'40px auto 32px auto'}
      >
        <Box
          position="absolute"
          sx={{
            top: '-36px',
            left: '-180px',
            '@media screen and (max-width: 400px)': {
              top: '-60px',
              left: '-145px',
            },
          }}
        >
          <LineDecoration width="270px" height="330px" />
        </Box>
      </Box>

      <DetailCard
        fullName={IS_BOY_FIRST ? BOY_NAME : GIRL_NAME}
        imgUrl={IS_BOY_FIRST ? IMG_MAN : IMG_GIRL}
        instagramId={IS_BOY_FIRST ? IG_BOY : IG_GIRL}
        description={IS_BOY_FIRST ? BOY_PARENT_NAME : GIRL_PARENT_NAME}
      />

      <DetailCard
        fullName={IS_BOY_FIRST ? GIRL_NAME : BOY_NAME}
        imgUrl={IS_BOY_FIRST ? IMG_GIRL : IMG_MAN}
        instagramId={IS_BOY_FIRST ? IG_GIRL : IG_BOY}
        description={IS_BOY_FIRST ? GIRL_PARENT_NAME : BOY_PARENT_NAME}
      />
    </Box>
  );
}

export default React.memo(CoupleInfo);
